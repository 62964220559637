import { gql } from "@apollo/client";

export const PAYER_SIGNATURE_BY_TOKEN = gql`
  query payerSignatureByToken($token: String!) {
    payerSignature: payerSignatureByToken(token: $token) {
      id
      documentUrl
      signedAt
      token
      order {
        identifier
      }
    }
  }
`;
