import { gql } from "@apollo/client";

export const SIGN_ORDER = gql`
  mutation signOrder($token: String!) {
    signOrder(token: $token) {
      id
      documentUrl
      signedAt
    }
  }
`;
