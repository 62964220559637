import React from "react";
import { hydrate, render } from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./assets/css/index.css";

const root = document.getElementById("root");
const init = root && root.hasChildNodes() ? hydrate : render;
init(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
