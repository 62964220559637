import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    sidebar: {
      width: number;
      closedWidth: number;
    };
  }
  interface ThemeOptions {
    sidebar: {
      width: number;
      closedWidth: number;
    };
  }
}

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    personal: {
      darkBlue: string;
      lightBlue: string;
      saumon: string;
      lightSaumon: string;
      begoniaDarker: string;
      begonia: string;
      yellow: string;
      lightYellow: string;
      lightOrange: string;
      yellowPostIt: string;
      corail: string;
      primaryTransparent: string;
    };
  }
  interface PaletteOptions {
    personal: {
      darkBlue: string;
      lightBlue: string;
      saumon: string;
      lightSaumon: string;
      begoniaDarker: string;
      begonia: string;
      yellow: string;
      lightYellow: string;
      lightOrange: string;
      yellowPostIt: string;
      corail: string;
      primaryTransparent: string;
    };
  }
}

const theme = createMuiTheme({
  props: {
    MuiOutlinedInput: {
      margin: "none",
      notched: false,
    },
    MuiFormControlLabel: {
      checked: true,
    },
    MuiInputLabel: {
      disableAnimation: true,
      shrink: false,
    },
    MuiSelect: {
      displayEmpty: true,
    },
  },
  palette: {
    text: {
      primary: "#1D1D1B",
      secondary: "#6B6B6B",
    },
    primary: {
      main: "#589970",
      light: "#BFE5CE",
      dark: "#2C6842",
    },
    secondary: {
      main: "#EB8E78",
      light: "rgba(235, 142, 120, 0.1)",
    },
    background: {
      default: "#F3F5F6",
      paper: "#FFFFFF",
    },
    error: {
      light: "rgba(219, 58, 55, 0.16)",
      main: "#DB3A37",
    },
    grey: {
      700: "#6B6B6B",
      400: "#AAAAAA",
      200: "#C7C7C7",
      100: "#F3F5F6",
    },
    warning: {
      light: "rgba(224, 67, 83, 0.1)",
      main: "#DB3A37",
    },
    info: {
      main: "#049BED",
    },
    success: {
      main: "#3AB772",
      light: "rgba(58, 183, 114, 0.1)",
    },
    personal: {
      darkBlue: "#3E4B7E",
      lightBlue: "rgba(4, 155, 237, 0.1)",
      saumon: "#EB8E78",
      lightSaumon: "rgba(235, 142, 120, 0.1)",
      begoniaDarker: "#E04353",
      begonia: "#FF6978",
      yellow: "#F9A600",
      lightYellow: "rgba(249, 166, 0, 0.1)",
      lightOrange: "#FDEBE5",
      yellowPostIt: "#FBF8B3",
      corail: "#FE5E55",
      primaryTransparent: "#76AB8B",
    },
    action: {
      disabled: "#FFF",
      disabledBackground: "#AAAAAA",
    },
    divider: "#F3F5F6",
  },
  typography: {
    fontFamily: "Helvetica Neue, Arial, sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 48,
      lineHeight: "54px",
    },
    h2: {
      fontFamily: "VAG Rounded Std, Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 32,
      lineHeight: "42px",
    },
    h3: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 24,
      lineHeight: "34px",
    },
    h4: {
      fontFamily: "VAG Rounded Std, Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "26px",
    },
    h5: {
      fontFamily: "VAG Rounded Std, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "26px",
    },
    subtitle1: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "24px",
    },
    subtitle2: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 16,
      lineHeight: "24px",
    },
    body1: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "21px",
    },
    body2: {
      fontFamily: "Helvetica Neue, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  sidebar: {
    width: 226,
    closedWidth: 80,
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontWeight: 500,
        minHeight: "30px !important",
      },
    },
    MuiButton: {
      root: {
        textTransform: "inherit",
        fontSize: 14,
        lineHeight: "21px",
        padding: "9px 32px",
      },
      outlined: {
        padding: "9px 32px",
      },
      outlinedSizeLarge: {
        padding: "9px 32px",
      },
      contained: {
        boxShadow: "none",
      },
      containedSizeLarge: {
        padding: "9px 32px",
        fontSize: 14,
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
      },
    },
    MuiIconButton: {
      root: {
        color: "#1D1D1B",
      },
    },
    MuiFormControlLabel: {
      root: {
        color: "#6B6B6B",
      },
    },
    MuiRadio: {
      root: {
        color: "#C7C7C7",
      },
      colorPrimary: {
        "&$checked": {
          color: "#EB8E78",
          "&:hover": {
            backgroundColor: "rgba(235, 142, 120, 0.04)",
          },
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 2,
      },
      notchedOutline: {
        top: 0,
        "& legend": {
          display: "none",
        },
      },
      input: {
        lineHeight: "24px",
        paddingTop: 13,
        paddingBottom: 13,
        fontSize: 16,
      },
      multiline: {
        padding: 0,
        paddingBottom: 24,
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: "initial !important",
        position: "relative",
        color: "#1D1D1B",
        marginBottom: 4,
        fontFamily: "Helvetica Neue, Arial",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "21px",
      },
    },
    MuiAccordion: {
      root: {
        "&.Mui-expanded": {
          margin: "0 !important",
          paddingBottom: 12,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        "&.Mui-expanded": {
          minHeight: 0,
        },
      },
      content: {
        "&.Mui-expanded": {
          margin: "12px 0",
        },
      },
      expandIcon: {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    MuiTableSortLabel: {
      root: {
        "&::after": {
          content: "url(files/sort-filter.svg)",
          position: "absolute",
          right: -18,
          top: 4,
        },
      },
      active: {
        "&::after": {
          content: "''",
        },
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "40px !important",
      },
    },
    MuiPaper: {
      outlined: {
        border: "solid 2px #C7C7C7",
      },
      rounded: {
        borderRadius: 5,
      },
      elevation1: {
        boxShadow: "0px 4px 20px rgba(34, 34, 34, 0.08)",
      },
      elevation2: {
        boxShadow: "0px 2px 14px rgba(107, 107, 107, 0.08)",
      },
    },
    MuiSwitch: {
      root: {
        height: 22,
        width: 44,
        padding: 0,
        borderRadius: 16,
        "&::before": {
          content: "'┘'",
          color: "#fff",
          position: "absolute",
          transform: "rotate(45deg)",
          left: 7,
          top: 6,
          fontSize: 14,
        },
        "&::after": {
          content: "'+'",
          color: "#fff",
          position: "absolute",
          transform: "rotate(45deg)",
          right: 5,
          top: 3,
          fontSize: 21,
          lineHeight: "16px",
        },
      },
      switchBase: {
        padding: 2,
        "&$checked": {
          transform: "translateX(22px)",
        },
      },
      colorSecondary: {
        "&$checked": {
          color: "#fff",
          "& + $track": {
            opacity: "1",
          },
        },
      },
      thumb: {
        height: 18,
        width: 18,
      },
      track: {
        opacity: "1",
        backgroundColor: "#BFBFBF",
      },
    },
  },
});

export default theme;
