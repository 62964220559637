import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { GraphQLProvider } from "./graphql";
import HomePage from "./pages/home";
import Layout from "./components/layout";
import OrderSignaturePage from "./pages/sign/OrderSignature";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <GraphQLProvider>
        <Router basename={"/"}>
          <Switch>
            <Route path="/sign/order" exact>
              <OrderSignaturePage />
            </Route>
            <Layout>
              <Route exact path="/">
                <HomePage />
              </Route>
            </Layout>
          </Switch>
        </Router>
      </GraphQLProvider>
    </ThemeProvider>
  );
}

export default App;
