import React, { FC, PropsWithChildren } from "react";

import "./styles.css";
import Menu from "../menu";

const Layout: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className="App">
    <header className="App-header">
      <Menu />
    </header>
    <main>{children}</main>
  </div>
);

export default Layout;
